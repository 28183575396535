import React from "react";
import IntegroLogo from "../../assets/site-logo.png";

import Typography  from "@mui/material/Typography";

export const TransaccionesHeader = ({ venta }) => {
  return (
    <>
      <div
        style={{
          paddingBottom: "6px",
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          alt="IntegroPOS"
          src={IntegroLogo}
          style={{
            width: "175px",
            height: "70px",
            marginTop: "-14px",
          }}
        />
        <Typography
          variant="h5"
          style={{
            marginTop: "-4px",
            marginBottom: "-4px",
            fontSize: 16,
            fontWeight: 800,
          }}
        >
          {venta.sucursal}
        </Typography>
        <Typography variant="h6" style={{ margin: "-2px", fontSize: 14 }}>
          {venta.cliente}
        </Typography>
        <Typography variant="h6" style={{ margin: "-2px", fontSize: 14 }}>
          Mesero: {venta.user} / Mesa: {venta.mesa}
        </Typography>
      </div>
    </>
  );
};

export default TransaccionesHeader;
