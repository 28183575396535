import React from 'react';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1, 0),
}));

const TotalTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export default function VistaPrevia(props) {
  const { data } = props;

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {`${data.fecha_apertura}`}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {`Sucursal: ${data.sucursal.nombre} Mesa: ${data.mesa}`}
      </Typography>
      <List disablePadding>
        {data.ventasdetalle.map((detalle) => (
          <ListItemStyled key={detalle.id}>
            <ListItemText primary={detalle.qty} secondary={detalle.descripcion} />
            <Typography variant="body2">{detalle.unit_price}</Typography>
          </ListItemStyled>
        ))}
        <ListItemStyled>
          <ListItemText primary="Total" />
          <TotalTypography variant="subtitle1">
            {data.total}
          </TotalTypography>
        </ListItemStyled>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TitleTypography variant="h6" gutterBottom>
            Descuento
          </TitleTypography>
          <Typography gutterBottom>{data.descuento}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <TitleTypography variant="h6" gutterBottom>
            Pago(s)
          </TitleTypography>
          <Grid container>
            {data.pagos.map((pago) => (
              <React.Fragment key={pago.id}>
                <Grid item xs={6}>
                  <Typography gutterBottom>{pago.formadepago.nombre}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{pago.total}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
