export const groupBySubfamilia = (arr) => {
  return arr.reduce((acc, item) => {
    // Find the subfamilia in the accumulator array
    let subfamiliaGroup = acc.find(
      (group) => group.subfamiliaId === item.subfamilia.id,
    );

    // If the subfamilia doesn't exist in the accumulator, create a new entry
    if (!subfamiliaGroup) {
      subfamiliaGroup = {
        subfamiliaId: item.subfamilia.id,
        nombre: item.subfamilia.nombre,
        orden: item.subfamilia.orden,
        activo: item.subfamilia.activo,
        platillos: [],
      };
      acc.push(subfamiliaGroup);
    }

    // Push the platillo into the appropriate subfamilia's platillos array
    subfamiliaGroup.platillos.push(item);

    return acc;
  }, []);
};
