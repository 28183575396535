import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import Facturacion from "./routes/Facturacion/Facturacion";
import PagosContainer from "./routes/Pagos";
import "./index.css";
import Recibo from "./routes/Recibo";
import Menu from "./routes/Menu";

// MUI Theme
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#e7e7e7",
    },
  },
});

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App({ company, host }) {
  const query = useQuery();
  const folio = query.get("folio");
  const ventaId = query.get("vid");
  const sucursalId = query.get("sid");
  const puntodeventaId = query.get("pid");

  return (
    <Routes>
      <Route
        path={`/`}
        element={
          folio ? (
            <Facturacion company={company} host={host} folio={folio} />
          ) : ventaId ? (
            <PagosContainer
              company={company}
              host={host}
              ventaId={ventaId}
              sucursalId={sucursalId}
              puntodeventaId={puntodeventaId}
            />
          ) : (
            <Facturacion company={company} host={host} folio={""} />
          )
        }
      />
      <Route
        path={`/menu`}
        element={<Menu company={company} sucursalId={sucursalId} />}
      />
      <Route
        path={`/pago/:id/recibo?`}
        element={<Recibo company={company} host={host} />}
      />
    </Routes>
  );
}

function AppWrapper() {
  const { company } = useParams();
  const host = `https://${company}.integro.app`;

  return <App company={company} host={host} />;
}

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <Routes>
        <Route path="/:company/*" element={<AppWrapper />} />
        <Route
          path={`/`}
          element={
            <div>Imposible detectar la empresa, favor de validar la URL.</div>
          }
        />
      </Routes>
    </Router>
  </ThemeProvider>,
  document.getElementById("root"),
);
