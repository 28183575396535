// api.js
import io from "socket.io-client";

const withTimeout = (onSuccess, onTimeout, timeout) => {
  let called = false;
  const timer = setTimeout(() => {
    if (called) return;
    called = true;
    onTimeout();
  }, timeout);

  return (...args) => {
    if (called) return;
    called = true;
    clearTimeout(timer);
    onSuccess.apply(this, args);
  };
};

// A utility function to create a delay
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

/**
 * GET MESA/VENTA FROM POS
 */
export const getPayment = (instance, ventaId, sucursalId, puntodeventaId) => {
  // Connecting to the message broker
  const socket = io("https://msgbroker.integro.app", { forceNew: true });
  return new Promise(async (resolve, reject) => {
    // Delay to ensure connection is established
    await delay(1000);
    if (!socket.connected) {
      console.error("Socket Not connected");
      return reject(new Error("Socket not connected"));
    }

    // Listen for errors
    socket.on("error", (error) => {
      reject(error);
    });

    // Emit the get_payment event to the server with a timeout handler
    socket.volatile.emit(
      "get_payment",
      { instance, ventaId, sucursalId, puntodeventaId },
      withTimeout(
        (response) => {
          // onResponse
          if (response.success) {
            resolve(response.data); // Resolve the promise with the data
          } else {
            console.error(response.message);
            reject(new Error(response.message)); // Reject the promise with the error message
          }
        },
        () => {
          // onTimeout
          reject(new Error("POS Timeout")); // Reject the promise with the timeout error
        },
        20000, // timeout in milliseconds
      ),
    );
  });
};

/**
 * CREATE PAYMENT INTENT
 *
 * @param {*} host
 * @param {*} paymentIntentData
 * @returns data the payment intent data that returns from the API
 */
export const createIntent = async (host, paymentIntentData) => {
  try {
    const response = await fetch(`${host}/terminales/transacciones`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(paymentIntentData), // Convert the payload to a JSON string
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

/**
 *  GET API PAYMENT CONFIRMATION
 *
 */
export const getPaymentConfirmation = async (company, ventaId) => {
  try {
    const response = await fetch(
      `https://${company}/terminales/confirmacion/${ventaId}`,
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getPaymentStatus = async (instance, portalId) => {
  return new Promise(async (resolve, reject) => {
    const socketListener = io("https://msgbroker.integro.app", {
      forceNew: true,
      reconnection: true,
      reconnectionDelay: 1000,
      query: {
        instance: instance,
        portalId: portalId,
      },
    });
    await delay(800);
    // Setting up a timeout for the payment status update
    const onTimeout = () => {
      console.error("Payment status update timeout");
      socketListener.disconnect();
      reject(
        new Error(
          "Se excedió el tiempo de espera de la respuesta del servidor de pago.",
        ),
      );
    };

    // Handler for the payment_status_change event
    const onPaymentStatusChange = withTimeout(
      (data) => {
        console.log("this notifies user of payment status", data);
        socketListener.disconnect();
        resolve(data);
      },
      onTimeout,
      30000, // timeout in milliseconds
    );

    socketListener.on("connect", () => {
      console.log("connection established", socketListener);
    });

    socketListener.on("payment_status_change", onPaymentStatusChange);

    socketListener.on("connect_error", (error) => {
      console.error("Connection Error:", error);
      reject(error); // Reject the promise if there's a connection error
    });
  });
};

export const getMenu = (instance, sucursalId) => {
  const socket = io("https://msgbroker.integro.app", {
    forceNew: true,
    reconnection: true,
    reconnectionDelay: 1000
  });

  return new Promise(async (resolve, reject) => {
    await delay(800);
    if (!socket.connected) {
      console.error("Socket Not connected");
      return reject(new Error("Socket not connected"));
    }

    // Listen for errors
    socket.on("error", (error) => {
      reject(error);
    });

    socket.volatile.emit(
      "get_menu",
      { instance, sucursalId },
      withTimeout(
        (response) => {
          // onResponse
          if (response.success) {
            console.log("response:", response);
            resolve(response); // Resolve the promise with the data
          } else {
            console.error(response);
            reject(new Error(response.message)); // Reject the promise with the error message
          }
        },
        () => {
          // onTimeout
          reject(new Error("POS Timeout")); // Reject the promise with the timeout error
        },
        30000, // timeout in milliseconds
      ),
    );
  });
};

export const getPosOnline = (instance, sucursalId) => {
  const socket = io("https://msgbroker.integro.app", { forceNew: true });
  return new Promise(async (resolve, reject) => {
    await delay(800);
    if (!socket.connected) {
      console.error("Socket Not connected");
      return reject(new Error("Socket not connected"));
    }

    // Listen for errors
    socket.on("error", (error) => {
      reject(error);
    });

    socket.volatile.emit(
      "pos_online",
      { instance, sucursalId },
      withTimeout(
        (response) => {
          // onResponse
          if (response.success) {
            resolve(response.data); // Resolve the promise with the data
          } else {
            console.error(response.message);
            reject(new Error(response.message)); // Reject the promise with the error message
          }
        },
        () => {
          // onTimeout
          reject(new Error("POS Timeout")); // Reject the promise with the timeout error
        },
        20000, // timeout in milliseconds
      ),
    );
  });
};
