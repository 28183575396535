import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';

export default function Folio(props) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Ingresa el Folio de tu Venta
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField required id="folio" label="Folio de AutoFacturación" fullWidth autoComplete="folio" value={props.folio || ''} onChange={e => props.onChange(e.target.value)} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
