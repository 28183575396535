import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";

import { useLocation, useParams } from "react-router-dom";

import IntegroLogo from "../../assets/site-logo.png";
import ImgPaymentSucceeded from "../../assets/payment_succeess.gif";
import ImgPaymentFailed from "../../assets/payment_failed.gif";
//import { getPaymentStatus } from "../../api";
//import { useParams } from "react-router-dom";
import Toast from "../../components/Toast";

const Recibo = ({company, host}) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const params = useParams();

  const queryParams = new URLSearchParams(location.search);
  const paymentIntent = queryParams.get("payment_intent");
  const redirectStatus = queryParams.get("redirect_status");

  // Handle the payment status accordingly
  if (redirectStatus === "succeeded") {
    // Payment was successful
    console.log("Payment succeeded!");
  } else if (redirectStatus === "paid") {
    // Already paid or cerrada
    console.log("Payment paid!");
  } else {
    // Payment failed
    console.error("Payment failed");
  }

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <center>
          <CircularProgress />
        </center>
      ) : (
        <Box sx={{ mx: 3, my: 4 }}>
          <Stack>
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <img
                src={IntegroLogo}
                alt="IntegroPOS"
                style={{ width: "200px", height: "auto" }}
              />
            </Box>
          </Stack>
          <br />
          <Card sx={{ width: "100%" }} backgroundColor="#F5F5F5">
            {
              redirectStatus === "succeeded" && 
                <CardContent>
                  <center>
                    <Typography variant="h2">Pago Exitoso</Typography>
                    <Typography variant="subtitle1">Venta: {params.id}</Typography>
                    <img src={ImgPaymentSucceeded} />
                  </center>
                  <Typography variant="body1">{paymentIntent ? `Identificador del Pago: ${paymentIntent}` : ``}</Typography>
                  <Typography variant="body1">{redirectStatus ? `Estatus del Pago: ${redirectStatus}` : ``}</Typography>
                </CardContent>
            }
            {
              redirectStatus === "paid" && 
                <CardContent>
                  <center>
                    <Typography variant="h2">Venta previamente pagada</Typography>
                    <Typography variant="subtitle1">Venta: {params.id}</Typography>
                    <img src={ImgPaymentSucceeded} />
                  </center>
                  <Typography variant="body1">{paymentIntent ? `Identificador del Pago: ${paymentIntent}` : ``}</Typography>
                  <Typography variant="body1">{redirectStatus ? `Estatus del Pago: ${redirectStatus}` : ``}</Typography>
                </CardContent>
            }
            {
              redirectStatus != "succeeded" && redirectStatus != "paid" && 
                <CardContent>
                  <center>
                    <Typography variant="h2">Falló el pago</Typography>
                    <Typography variant="subtitle1">Venta: {params.id}</Typography>
                    <img src={ImgPaymentFailed} />
                  </center>
                  <Typography variant="body1">{paymentIntent ? `Identificador del Intento: ${paymentIntent}` : ``}</Typography>
                  <Typography variant="body1">{redirectStatus ? `Estatus del Pago: ${redirectStatus}` : ``}</Typography>
                </CardContent>
            }
          </Card>
          <Toast
            errorMsg={error}
            open={!!error}
            handleClose={setError}
            severity="error"
          />
        </Box>
      )}
    </>
  );
};

export default Recibo;
