import React, { Fragment, useMemo } from "react";

import Divider from "@mui/material/Divider";
import MobileStepper from "@mui/material/MobileStepper";
import SwipeableViewContent from "./SwipeableViewContent";
import SwipeableViews from "react-swipeable-views";

const MAX_STEPS = 2;
const CURRENCY_FORMAT = "$0,0.00";

const SwipeableViewsComponent = ({ venta, handleStepChange, activeStep }) => {
  const swipeableViewsChildren = useMemo(() => {
    let children = [];

    if (venta && venta.pagos && venta.pagos.length > 0) {
      children.push(
        <SwipeableViewContent
          key="pagos"
          data={venta.pagos}
          title="Pagos"
          format={CURRENCY_FORMAT}
        />
      );
    }

    if (venta && venta.propinas && venta.propinas.length > 0) {
      children.push(
        <SwipeableViewContent
          key="propinas"
          data={venta.propinas}
          title="Propinas"
          format={CURRENCY_FORMAT}
        />
      );
    }

    return children;
  }, [venta]);

  return (
    <div>
      {((venta && venta.pagos && venta.pagos.length > 0) ||
        (venta.propinas && venta.propinas.length > 0)) && (
        <Fragment>
          <SwipeableViews
            index={activeStep}
            enableMouseEvents
            onChangeIndex={handleStepChange}
          >
            {swipeableViewsChildren}
          </SwipeableViews>
          {venta &&
            venta.pagos &&
            venta.pagos.length > 0 &&
            venta.propinas &&
            venta.propinas.length > 0 && (
              <MobileStepper
                style={{
                  width: "100%",
                  justifyContent: "center",
                  p: 0,
                  m: 0,
                  backgroundColor: "transparent",
                }}
                variant="dots"
                steps={MAX_STEPS}
                position="static"
                activeStep={activeStep}
              />
            )}
          <Divider />
        </Fragment>
      )}
    </div>
  );
};

export default SwipeableViewsComponent;
