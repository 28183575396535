import React from "react";
import { Snackbar, Alert, Typography, Slide } from "@mui/material";

const messageMap = {
  "Your card has insufficient funds.": "Saldo insuficiente",
  "Your card has been declined.": "Tarjeta declinada",
  "Your postal code is incomplete.": "Código postal incompleto",
  "Your card's security code is incomplete.": "Código de seguridad incompleto",
  "Your card's expiration date is incomplete.": "Fecha de expiración incompleta"
}

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const Toast = ({ errorMsg, open, handleClose, severity }) => {

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      TransitionComponent={SlideTransition}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        <Typography variant="h5">{messageMap[errorMsg] || errorMsg}</Typography>
      </Alert>
    </Snackbar>
  );
};

export default Toast;
