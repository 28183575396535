import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import numeral from "numeral";
import { Button, ButtonGroup } from "@mui/material";
import Toast from "../../components/Toast";
import { useNavigate } from "react-router-dom";

export default function CheckoutForm({
  amount,
  ventaId,
  handlePaymentIntent,
  handleShowCustomAmount,
  showCustomAmount,
  sumAmount,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleError = (error) => {
    // Check if the component is still mounted before setting state
    setLoading(false);
    setErrorMessage(error.message);
    setOpen(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      return;
    }

    setLoading(true);

    try {
      // Submit the Payment Element to collect payment details
      const { error: submitError } = await elements.submit();

      if (submitError) {
        // Handle errors from submitting the Payment Element
        handleError(submitError);
        return;
      }

      //let return_url = `${process.env.REACT_APP_STRIPE_RETURN_URL}/${company}/pago/${ventaId}/recibo`;
      const { error: confirmationError, confirmationToken } =
        await stripe.createConfirmationToken({
          elements,
          params: {},
        });
      if (confirmationError) {
        // This point is only reached if there's an immediate error when
        // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
        handleError(confirmationError);
        return;
      }

      // If the submit was successful, call the handlePaymentIntent function
      const paymentIntentResponse =
        await handlePaymentIntent(confirmationToken);

      if (paymentIntentResponse.error) {
        // Show error from server on payment form
        handleError(paymentIntentResponse.error);
      } else if (paymentIntentResponse.status === "requires_action") {
        // Use Stripe.js to handle the required next action
        const { error } = await stripe.handleNextAction({
          clientSecret: paymentIntentResponse.clientSecret,
        });

        if (error) {
          // Show error from Stripe.js in payment form
          handleError(error);
        } else {
          // Actions handled, show success message
          navigate(
            `pago/${ventaId}/recibo?payment_intent=${paymentIntentResponse.id}&redirect_status=${paymentIntentResponse.status}`,
          );
        }
      } else {
        // No actions needed, show success message
        navigate(
          `pago/${ventaId}/recibo?payment_intent=${paymentIntentResponse.id}&redirect_status=${paymentIntentResponse.status}`,
        );
      }

      /*
      if (isMounted.current) {
        // Assuming handlePaymentIntent returns the PaymentIntent object
        // which includes the client_secret.
        const clientSecret = paymentIntentResponse.intent.client_secret;



        // Confirm the PaymentIntent using the details collected by the Payment Element
        const { error: confirmationError } = await stripe.confirmPayment({
          elements,
          clientSecret: clientSecret,
          confirmParams: {
            return_url: return_url,
          },
        });

        if (confirmationError) {
          // Handle errors during the confirmation
          handleError(confirmationError);
        }
      }
      */
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const buttonLabel = !showCustomAmount ? `Otro Monto` : `Agregar Propina`;

  return (
    <>
      <Toast
        errorMsg={errorMessage}
        open={open}
        handleClose={handleClose}
        severity="error"
      />
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <br />
        <>
          <ButtonGroup
            style={{ height: "60px" }}
            variant="contained"
            color="primary"
            fullWidth
            disabled={!stripe || loading}
          >
            <Button
              disabled={amount <= 50}
              onClick={handleSubmit}
            >{`Pagar ${numeral(sumAmount).format("$0,0.00")}`}</Button>
            <Button onClick={handleShowCustomAmount}>{buttonLabel}</Button>
          </ButtonGroup>
        </>
      </form>
    </>
  );
}
