import React from "react";
import {
    Box,
    Card,
    CardContent,
    Typography,
} from "@mui/material";
import numeral from "numeral";

const SwipeableViewContent = ({ data, title, format }) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Box sx={{ pt: 1, px: 0.4, pb: 0.4, flex: 1 }}>
          <Card
            elevation={3}
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <CardContent
              style={{ flex: 1, padding: "8px", paddingBottom: "6px" }}
            >
              <Typography>
                <b>{title}</b>
              </Typography>
              {data.map((item) => (
                <div
                  key={item.id}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: 12,
                  }}
                >
                  <span>{item.formadepago.nombre}</span>
                  <span>{numeral(item.total).format(format)}</span>
                </div>
              ))}
            </CardContent>
          </Card>
        </Box>
      </div>
    );
  };
  
  export default SwipeableViewContent;