import React from "react";
import {
  Box,
  ButtonGroup,
  Typography,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import numeral from "numeral";

export default function TipButtonGroup({
  amount: total,
  activeTip,
  customTip,
  handleTipChange,
  showCustomTip,
  setShowCustomTip,
  handleCustomTipChange
}) {

    const handleShowCustomTip = () => {
    setShowCustomTip(!showCustomTip);
    handleTipChange(-1);
  };

  const handleToggle = (tip) => {
    setShowCustomTip(false);
    handleTipChange(tip);
  };


  const renderButton = (tip, key) => (
    <Button
      onClick={() => handleToggle(tip)}
      style={{ backgroundColor: activeTip === tip ? "darkgrey" : "" }}
      color="secondary"
      key={key}
    >
      <Box>
        <Typography>{tip * 100}%</Typography>
        {total && (
          <Typography>{numeral(total * tip).format("$0,0.00")}</Typography>
        )}
      </Box>
    </Button>
  );


  return (
    <>
      <h3>No olvides agregar propina</h3>
      <ButtonGroup variant="contained" fullWidth sx={{ mb: 1 }}>
        {[0.10, 0.15, 0.2].map((tip, i) => renderButton(tip, i))}
        <Button
          onClick={handleShowCustomTip}
          style={{ backgroundColor: activeTip === -1 ? "darkgrey" : "" }}
          color="secondary"
        >
          <Typography>Propina distinta</Typography>
        </Button>
      </ButtonGroup>
      {showCustomTip && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mb: 1,
            alignItems: "center",
          }}
        >
          <Typography sx={{ mr: 1 }}>Ingresar propina:</Typography>
          <TextField
            variant="outlined"
            size="small"
            placeholder="0.00"
            value={customTip}
            onChange={handleCustomTipChange}
            autoFocus
            inputProps={{ style: { textAlign: "right" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Box>
      )}
    </>
  );
}
