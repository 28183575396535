import React, { Fragment} from "react";

import TextField  from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const CustomPaymentAmount = ({
    customAmountRef,
    customAmount,
    handleCustomAmountChange,
    showCustomAmount
}) => {
  return (
    <div>
      {" "}
      <Fragment>
        <br />
        <TextField
          inputRef={customAmountRef}
          label="Otro monto"
          value={customAmount}
          onChange={handleCustomAmountChange}
          fullWidth
          placeholder="0.00"
          autoFocus
          inputProps={{ style: { textAlign: "right" } }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          sx={{
            display: showCustomAmount ? "block" : "none",
          }}
        />
        <br />
      </Fragment>
    </div>
  );
};

export default CustomPaymentAmount;