import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function DescargaFactura(props) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Factura Emitida con Éxito
      </Typography>
      <Grid container spacing={3}>
        {
          props.data.attachments.map((a, i) => {
            return (
              <Grid key={i} item xs={6} md={6}>
                <Button variant="contained" color="primary" target="_blank" href={`${a.url}`} download={`${a.name}`}>
                    {`${a.name}`}
                </Button>
              </Grid>
            )
          })
        }
      </Grid>
    </React.Fragment>
  );
}
