import React, { useState, useEffect, useCallback } from "react";
import { getPayment } from "../../api";
import Pagos from "./Pagos";
import LoadingOrError from "../../components/LoadingOrError";
import { useNavigate } from "react-router-dom";

const PagosContainer = ({
  company,
  host,
  ventaId,
  sucursalId,
  puntodeventaId,
}) => {
  const [venta, setVenta] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = React.useState(null);
  const [parsedTotal, setParsedTotal] = useState(null);
  const navigate = useNavigate();

  const handleFetchVenta = useCallback(() => {
    setLoading(true);
    var instance = host
      ? host
          .replaceAll("http://", "")
          .replaceAll("https://", "")
          .replaceAll("/", "")
      : "URL_NOT_FOUND";
    getPayment(instance, ventaId, sucursalId, puntodeventaId)
      .then((res) => {
        if (res.venta.cerrada || (res.venta.total-res.venta.pagado<=0)) {
          navigate(`pago/${res.venta.id}/recibo?redirect_status=paid`);
        } else {
          setVenta({
            id: res.venta.id,
            corte: res.venta.corteId,
            cliente: res.venta.cliente.nombre,
            sucursal: res.venta.sucursal.nombre,
            sucursalId: res.venta.sucursalId,
            ventasdetalle: res.venta.ventasdetalle,
            descuento: res.venta.descuento,
            subtotal: res.venta.subtotal,
            impuesto: res.venta.impuesto,
            total: res.venta.total,
            pagos: res.venta.pagos,
            personas: res.venta.personas,
            pagado: res.venta.pagado,
            propinas: res.venta.propinas,
            mesa: res.venta.mesa,
            user: res.venta.usersdesktop.nombre,
            userId: res.venta.usersdesktop.id,
            cancelada: res.venta.cancelada,
            cerrada: res.venta.cerrada,
            accountId: res.accountId,
          });
          if (+res.venta.total != null && !isNaN(+res.venta.total)) {
            try {
              //const stripeBig = new Big(100);
              const total = Number(res.venta.total) - Number(res.venta.pagado);
              //const calculatedTotal = total.times(stripeBig);
              setParsedTotal(Number(total.toFixed(2))); // This converts it to a number
            } catch (error) {
              console.error("Error calculating ParsedTotal:", error);
            }
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err);
        setLoading(false);
      });
  }, [host, ventaId, sucursalId, puntodeventaId, navigate]);

  useEffect(() => {
    handleFetchVenta();
  }, [handleFetchVenta]);

  return (
    <div>
      <LoadingOrError
        loading={loading}
        error={error}
        cancelada={venta.cancelada}
      />
      {!loading && !venta.cancelada && (
        <Pagos
          company={company}
          host={host}
          ventaId={ventaId}
          sucursalId={sucursalId}
          puntodeventaId={puntodeventaId}
          venta={venta}
          error={error}
          loading={loading}
          total={parsedTotal}
        />
      )}
    </div>
  );
};

export default PagosContainer;
