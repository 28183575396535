import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import IntegroLogo from '../../assets/site-logo.png';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    width: '100vw',
    height: '60vh',
    flexDirection: 'column',
    textAlign: 'center',
  },
  logo: {
    width: '200px',
    height: 'auto',
    mt: '400px',
  },
};

const LoadingOrError = ({ loading, error, cancelada }) => {
  if (error) {
    return (
      <Box sx={styles.container}>
        <img src={IntegroLogo} alt='IntegroPOS' style={styles.logo} />
        <h3>'Lo sentimos, no pudimos encontrar tu ticket.<br /> Por favor, intenta nuevamente.'</h3>
      </Box>
    );
  }

  if(cancelada){
    return (
      <Box sx={styles.container}>
        <img src={IntegroLogo} alt='IntegroPOS' style={styles.logo} />
        <h3>Esta venta ha sido cancelada.</h3>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box sx={styles.container}>
        <CircularProgress />
      </Box>
    );
  }

  return null;
};

export default LoadingOrError;
