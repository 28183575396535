import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Container as MuiContainer, Typography } from "@mui/material";

import fallback_sucursal from "../../assets/fallback_sucursal.jpg";

const Container = ({ children, sucursal }) => {
  return (
    <React.Fragment>
      <CssBaseline />
      <MuiContainer maxWidth="lg">
        <Box
          sx={{
            
            backgroundImage: `url(${fallback_sucursal})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "30vh",
            mt: 2,
            borderRadius: "4px",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="h3">{(sucursal && sucursal.nombre) ? sucursal.nombre : "Restaurant"}</Typography>
        </Box>
        <center>
          <Typography variant="h5">{(sucursal && sucursal.email) ? sucursal.email : ""} {(sucursal && sucursal.telefono) ? sucursal.telefono : ""}</Typography>
        </center>
        {children}
      </MuiContainer>
    </React.Fragment>
  );
};

export default Container;
