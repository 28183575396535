import React from "react";
import { Typography, Box, CardMedia, Grid, Divider } from "@mui/material";
import PropTypes from "prop-types";
import fallback_plate from "../../assets/fallback_plate.jpg";
import numeral from "numeral";
import { CURRENCY_FORMAT } from "../../utils/currency";

function TabPanel(props) {
  const { children, value, index, platillos, nombre, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box sx={{ py: 3 }}>
        <Typography component="div" variant="h6">
          {nombre}
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {platillos
            .filter((platillo) => platillo.activo)
            .map((platillo, platilloIndex) => (
              <Grid item key={platilloIndex} xs={12} sm={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 1,
                  }}
                >
                  <Box
                    sx={{ display: "flex", flexDirection: "column", flex: 1 }}
                  >
                    <Box sx={{ flex: "1 0 auto" }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold" }}
                      >
                        {platillo.nombre}
                      </Typography>
                      <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                        {numeral(platillo.precio).format(CURRENCY_FORMAT)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {platillo.descripcion}
                      </Typography>
                    </Box>
                  </Box>
                  <CardMedia
                    component="img"
                    sx={{ width: 135, borderRadius: "4px" }}
                    image={platillo.photo || fallback_plate}
                    alt={`imagen-${platillo.nombre}`}
                  />
                </Box>
                <Divider />
              </Grid>
            ))}
        </Grid>
      </Box>
    </div>
  );
}

export default TabPanel;

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  platillos: PropTypes.array.isRequired,
  nombre: PropTypes.string.isRequired,
};
