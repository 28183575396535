import React from 'react';
import { styled } from '@mui/system';
import {
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableFooter,
    Table,
} from '@mui/material';

// intl messages
import numeral from 'numeral';

function totalization(qty, price) {
    let n = +qty * +price;
    return n.toFixed(2);
}

function LabelTotales({ label, value }) {
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '2px', color: 'black' }}>
            <span style={{ textAlign: 'right', marginRight: '20px' }}>{label}</span>
            <span style={{ width: '80px', textAlign: 'right' }}>
                {numeral(value).format('$0,0.00')}
            </span>
        </div>
    );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    head: {
        fontSize: 12,
        padding: '8px',
    },
    body: {
        fontSize: 12,
        padding: '6px',
    },
    footer: {
        fontSize: 14,
        padding: '6px 6px',
    },
}));

export default function SingleCheck({ venta }) {
    const { subtotal, descuento, impuesto, total, ventasdetalle } = venta;

    return (
        <Table sx={{ width: '100%' }}>
            <TableHead>
                <TableRow sx={{ display: 'flex', width: '100%' }}>
                    <StyledTableCell>Cantidad</StyledTableCell>
                    <StyledTableCell sx={{ flex: 3 }}>Producto</StyledTableCell>
                    <StyledTableCell align='right'>Precio total</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {ventasdetalle &&
                    ventasdetalle.map((producto, key) => (
                        <TableRow sx={{ display: 'flex', width: '100%' }} key={key}>
                            <StyledTableCell>({producto.qty})</StyledTableCell>
                            <StyledTableCell sx={{ flex: 3 }}>{producto.descripcion}</StyledTableCell>
                            <StyledTableCell align='right'>$ {totalization(producto.qty, producto.unit_price)}</StyledTableCell>
                        </TableRow>
                    ))}
            </TableBody>
            <TableFooter>
                <TableRow sx={{ display: 'flex', justifyContent:'flex-end', width: '100%' }}>
                    <StyledTableCell />
                    <StyledTableCell />
                    <StyledTableCell>
                        <LabelTotales label='Subtotal:' value={subtotal} />
                        <LabelTotales label='Descuento:' value={descuento} />
                        <LabelTotales label='Impuesto:' value={impuesto} />
                        <b>
                            <LabelTotales label='Total:' value={total} />
                        </b>
                    </StyledTableCell>
                </TableRow>
            </TableFooter>
        </Table>
    );
}

export { LabelTotales, totalization };
