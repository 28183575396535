import React, { useRef, useState, useCallback, useEffect } from "react";
import { Tab, Tabs, Box, IconButton } from "@mui/material";
import Container from "./Container";
import TabPanel from "./TabPanel";
// api get menu
import { getMenu } from "../../api";

import SwipeableEdgeDrawer from "../../components/SubfamiliasDrawer";

import { groupBySubfamilia } from "./utils";

import MenuIcon from "@mui/icons-material/Menu";
// Icon import

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const Menu = ({ company, sucursalId }) => {
  const [selectedSubfamilia, setSelectedSubfamilia] = useState(0);
  const [open, setOpen] = useState(false);
  const tabPanelRefs = useRef([]);
  const [subfamilias, setSubfamilias] = useState([]);
  const [sucursal, setSucursal] = useState([]);
  const instance = company + ".integro.app";

  const handleSubfamilaSelection = (event, newValue) => {
    scrollToSubfamilia(newValue);
    setSelectedSubfamilia(newValue);
  };

  const handleDrawerSelectionChange = (newValue) => {
    scrollToSubfamilia(newValue);
    setSelectedSubfamilia(newValue);
    setOpen(false);
  };

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen);
  };

  const scrollToSubfamilia = useCallback((index) => {
    if (tabPanelRefs.current[index]) {
      tabPanelRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      tabPanelRefs.current[index].focus();
    }
  }, []);

  const handleFetchMenu = useCallback(() => {
    getMenu(instance, sucursalId)
      .then((response) => {
        setSubfamilias(groupBySubfamilia(response.menu));
        setSucursal(response.sucursal);
      })
      .catch((err) => console.error(err));
  }, [instance, sucursalId]);

  useEffect(() => {
    handleFetchMenu();
  }, [handleFetchMenu]);

  if (subfamilias.length) {
    return (
      <Container sucursal={sucursal}>
        <SwipeableEdgeDrawer
          open={open}
          toggleDrawer={toggleDrawer}
          handleDrawerSelectionChange={handleDrawerSelectionChange}
          subfamilias={subfamilias}
        />
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1000,
            backgroundColor: "white",
            display: "flex",
          }}
        >
          <IconButton
            aria-label="subfamilas-list"
            onClick={() => toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Tabs
            value={selectedSubfamilia}
            onChange={handleSubfamilaSelection}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            {subfamilias
              ?.filter((sub) => sub.activo)
              ?.map((subfamilia, subfamiliaIndex) => (
                <Tab
                  key={`tab-${subfamilia.nombre}-${subfamiliaIndex}`}
                  label={subfamilia.nombre}
                  {...a11yProps(subfamiliaIndex)}
                />
              ))}
          </Tabs>
        </Box>
        <br />
        {subfamilias
          ?.filter((sub) => sub.activo)
          ?.map((subfamilia, subfamiliaIndex) => (
            <div
              key={`${subfamilia.nombre}-panel-${subfamiliaIndex}`}
              ref={(el) => {
                tabPanelRefs.current[subfamiliaIndex] = el;
              }}
              tabIndex={selectedSubfamilia === subfamiliaIndex ? 0 : -1} // Make the element focusable
            >
              <TabPanel
                index={subfamiliaIndex}
                value={selectedSubfamilia}
                platillos={subfamilia.platillos}
                nombre={subfamilia.nombre}
              />
            </div>
          ))}
      </Container>
    );
  } else {
    return null;
  }
};

export default Menu;
