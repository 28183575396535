import React, { useState, useEffect, Fragment, useRef } from "react";

// MUI imports
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// library imports
import numeral from "numeral";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// component imports
import TipButtonGroup from "./TipButtonGroup";
import CheckoutForm from "./CheckoutForm";
import CheckoutTabs from "./CheckoutTabs";
import TransaccionesHeader from "./TransaccionesHeader";
import CustomPaymentAmount from "./CustomPaymentAmount";
import SwipeableViewsComponent from "./SwipeableViewsComponent";

// api imports
import { createIntent } from "../../api";

const Transacciones = ({
  company,
  host,
  ventaId,
  sucursalId,
  puntodeventaId,
  venta,
  loading,
  total,
}) => {
  const customAmountRef = useRef(null);
  //swipeable component tabs
  const [activeStep, setActiveStep] = useState(0);
  // state for the tabs
  const [activeTab, setActiveTab] = useState(0);
  const [activeTip, setActiveTip] = useState(null);

  const [showCustomTip, setShowCustomTip] = useState(false);
  const [showCustomAmount, setShowCustomAmount] = useState(false);

  const [amount, setAmount] = useState(total); // A PAGAR SIN PROPINA
  const [tipAmount, setTipAmount] = useState(0); // A PAGAR DE PROPINA

  const [customAmount, setCustomAmount] = useState(0); // A PAGAR SIN PROPINA MANUAL
  const [customTip, setCustomTip] = useState(0); // MONTO DE PROPINA DISTINTA

  const [sumAmount, setSumAmount] = useState(total); // SUMA TOTAL A PAGAR INCLUYENDO PROPINA

  const [stripePromise, setStripePromise] = useState(null);

  // Constants
  const CENTS_PER_UNIT = 100; // For currency conversion
  const CURRENCY_FORMAT = "$0,0.00";

  const formatCurrency = (value) => {
    //return (value / CENTS_PER_UNIT).toFixed(2);
    value = Math.max(value, 0); // Ensure non-negative
    return Math.round(value * 100) / 100; // Fix 2 decimals
  };

  const convertToStripeFormat = (value) => {
    return Math.round(value * CENTS_PER_UNIT);
  };

  // reset state function
  const resetTip = () => {
    setActiveTip(null);
    setCustomTip(0);
    setTipAmount(0);
    setShowCustomTip(false);
    setSumAmount(amount);
  };

  /**
   * @param {number} newTipPercentage
   * @param {number} currentTotal
   *
   *  This function is used to update the tip amount and the total amount
   *  based on the selection of the button group
   */
  const handleTipChange = (newTipPercentage) => {
    if (newTipPercentage === activeTip) {
      resetTip();
    } else if (newTipPercentage === -1) {
      setActiveTip(-1);
      setCustomTip(0);
      setTipAmount(0);
      setSumAmount(amount);
    } else {
      setActiveTip(newTipPercentage);
      setCustomTip(0);
      var currentTip = amount * newTipPercentage;
      setTipAmount(currentTip);
      setSumAmount(amount + currentTip);
    }
  };

  /**
   * @param {*} event
   *
   *  This function is used to update the tip amount and the total amount
   *  based on the selection of the custom tip input
   */
  const handleCustomTipChange = (event) => {
    let inputValue = Number(event.target.value); // Raw input value
    inputValue = Math.max(inputValue, 0); // Ensure non-negative

    setCustomTip(formatCurrency(inputValue)); // Set formatted currency for display
    setTipAmount(inputValue);
    setSumAmount(amount + inputValue);
  };

  /**
   * @param {*} event
   *
   *  This functions handles the custom total amount selected by the user
   */
  const handleCustomAmountChange = (event) => {
    let inputValue = Number(event.target.value); // Raw input value
    inputValue = Math.max(inputValue, 0); // Ensure non-negative

    setCustomAmount(formatCurrency(inputValue));
    setAmount(inputValue);
    setSumAmount(inputValue + tipAmount);
  };

  // Cuando cambia el importe del asiento seleccionado
  const handleSeatsTotals = (seatTotal) => {
    resetTip();
    setAmount(seatTotal);
    setSumAmount(seatTotal + tipAmount);
  };

  const handleShowCustomAmount = () => {
    if (showCustomAmount) {
      // SE DEJA DE MOSTRAR
      setActiveTab(0);
      resetTip();
      setCustomAmount(0);
      setAmount(total);
      setSumAmount(total);
    } else {
      // SE EMPIEZA A MOSTRAR
      setActiveTab(0);
      resetTip();
      setCustomAmount(0);
      setAmount(0);
      setSumAmount(0);
    }
    setShowCustomAmount(!showCustomAmount);
  };

  const handleTabChange = (event, newValue) => {
    resetTip();
    if (newValue === 0) {
      // SINGLE CHECK
      setCustomAmount(0);
      setAmount(total);
      setSumAmount(total);
    } else {
      // POR SIENTO
      setCustomAmount(0);
    }

    setActiveTab(newValue);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handlePaymentIntent = async (confirmationToken) => {
    try {
      const paymentIntentData = {
        confirmationTokenId: confirmationToken.id,
        amount: convertToStripeFormat(Number(sumAmount)),
        amount_details: {
          tip: {
            amount: Number(tipAmount),
          },
        },
        metadata: {
          instance: company,
          sucursalId: venta.sucursalId,
          puntodeventaId: puntodeventaId,
          ventaId: ventaId,
          userdesktopId: venta.userId,
          monto: Number(sumAmount),
          propina: Number(tipAmount),
        },
      };

      // Await the async call to createIntent and return the result
      const res = await createIntent(host, paymentIntentData);
      return res;
    } catch (err) {
      // If there's an error, log it and rethrow or handle it as needed
      console.error(err);
      throw err;
    }
  };

  useEffect(() => {
    if (venta && venta.total && activeTab === 0) {
      setAmount(total);
      setSumAmount(total);
    }
  }, [venta, activeTab]);

  useEffect(() => {
    const stripeObject = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
      stripeAccount: venta.accountId,
    });
    setStripePromise(stripeObject);
  }, [venta]);

  const options = {
    mode: "payment",
    amount: convertToStripeFormat(Number(total)),
    currency: "mxn",
    payment_method_types: ["card"],
  };

  return (
    <>
      {Object.keys(venta).length !== 0 && (
        <Box style={{ overflowY: "auto", width: "100%" }}>
          <Card>
            <CardContent>
              <TransaccionesHeader venta={venta} />
              <Box sx={{ width: "100%" }}>
                <CheckoutTabs
                  handleTabChange={handleTabChange}
                  loading={loading}
                  venta={venta}
                  activeTab={activeTab}
                  handleSeatsTotals={handleSeatsTotals}
                  resetTip={resetTip}
                />
              </Box>
              <Box>
                <SwipeableViewsComponent
                  venta={venta}
                  activeStep={activeStep}
                  handleStepChange={handleStepChange}
                />
                <Box sx={{ px: 2, pt: 1 }}>
                  {venta.pagado > 0 ? (
                    <Fragment>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h1>Restante Por Pagar:</h1>
                        <h1>{numeral(total).format(CURRENCY_FORMAT)}</h1>
                      </Box>
                    </Fragment>
                  ) : null}
                </Box>
                <Divider />
                <Box sx={{ py: 1, mb: 1 }}>
                  <Fragment>
                    {!showCustomAmount && (
                      <TipButtonGroup
                        activeTip={activeTip}
                        amount={amount}
                        handleTipChange={handleTipChange}
                        handleCustomTipChange={handleCustomTipChange}
                        customTip={customTip}
                        showCustomTip={showCustomTip}
                        setShowCustomTip={setShowCustomTip}
                      />
                    )}
                    {showCustomAmount && (
                      <CustomPaymentAmount
                        customAmountRef={customAmountRef}
                        customAmount={customAmount}
                        handleCustomAmountChange={handleCustomAmountChange}
                        showCustomAmount={showCustomAmount}
                      />
                    )}
                  </Fragment>
                  {stripePromise ? (
                    <Elements stripe={stripePromise} options={options}>
                      <CheckoutForm
                        amount={amount}
                        ventaId={ventaId}
                        company={company}
                        handlePaymentIntent={handlePaymentIntent}
                        handleShowCustomAmount={handleShowCustomAmount}
                        showCustomAmount={showCustomAmount}
                        sumAmount={sumAmount}
                      />
                    </Elements>
                  ) : (
                    <Fragment />
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
};

export default Transacciones;
