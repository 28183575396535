import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Tabs,
    Tab,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Radio,
    TableFooter,
    TableRow,
    TableCell,
    Table,
    Paper,
    CircularProgress,
} from '@mui/material';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ReceiptIcon from '@mui/icons-material/Receipt';

import SingleCheck, { LabelTotales } from './SingleCheck';
import numeral from 'numeral';


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// loader
//import RctSectionLoader from "Components/RctSectionLoader/RctSectionLoader";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    head: {
        fontSize: 12,
    },
    body: {
        fontSize: 12,
        padding: '10px'
    },
    footer: {
        fontSize: 14,
        padding: '6px 12px',
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    {children}
                </Box>
            )}
        </div>
    );
}


/**
 *  Group an array of objects by a property, build an object with "Totales por asiento"
 */
function groupBy(array) {
    return array.reduce((result, currentValue) => {
        const key = currentValue.persona;
        if (!result[key]) {
            result[key] = {
                ventasdetalle: [],
                subtotal: 0,
                descuento: 0,
                impuesto: 0,
                total: 0,
            };
        }

        const subtotal = currentValue.qty * parseFloat(currentValue.unit_price);
        result[key].subtotal += subtotal;
        result[key].descuento += parseFloat(currentValue.descuento);
        result[key].impuesto += parseFloat(currentValue.impuesto);
        result[key].total += subtotal - parseFloat(currentValue.descuento) + parseFloat(currentValue.impuesto);
        result[key].ventasdetalle.push(currentValue);

        return result;
    }, {});
}

export default function CheckoutTabs({ activeTab, handleTabChange, venta, loading, handleSeatsTotals, resetTip }) {

    const { personas, ventasdetalle } = venta
    const [selectedSeats, setSelectedSeats] = React.useState([]);
    const [selectedTotal, setSelectedTotal] = React.useState(0);

    const seats = groupBy(ventasdetalle, 'persona');

    const handleUserSelectedSeats = (e, seat, monto) => {
        resetTip()
        e.stopPropagation();
        const index = selectedSeats.indexOf(seat);
        if (index > -1) {
            setSelectedSeats(selectedSeats.filter(s => s !== seat));
            const newTotal = Number(selectedTotal) - Number(monto);
            handleSeatsTotals(newTotal);
            setSelectedTotal(newTotal);
        } else {
            setSelectedSeats([...selectedSeats, seat]);
            const newTotal = Number(selectedTotal) + Number(monto);
            handleSeatsTotals(newTotal);
            setSelectedTotal(newTotal);
        }
    };

    useEffect(() => {
        if(activeTab === 0){
            setSelectedSeats([]);
            setSelectedTotal(0);
        }
    }, [activeTab]);

    return (
        <>
            {venta.personas > 1 ? ( 
                <>
                    <Paper square>
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            variant="fullWidth"
                            aria-label="icon label tabs example"
                            indicatorColor='primary'
                            style={{ backgroundColor: '#e0e0e0', height: '70px' }}
                        >
                            <Tab icon={<ReceiptIcon />} label={"Una sola cuenta"} />
                            <Tab icon={<SupervisedUserCircleIcon />} label={"Cuenta por asiento"} disabled={personas <= 1} />
                        </Tabs>
                    </Paper>
                    <TabPanel value={activeTab} index={0}>
                        {loading
                            ? <CircularProgress />
                            : <SingleCheck venta={venta} />
                        }
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>

                        {
                            loading
                                ? <CircularProgress />
                                : Object.entries(seats).map(([seat, seatVenta]) => {
                                    return (
                                        <Accordion key={seat} style={{borderRadius:'0px'}}>
                                            <AccordionSummary
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                expandIcon={<ExpandMoreIcon />}
                                            >
                                                <Box display="flex" sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Typography>ASIENTO {seat}</Typography>
                                                    <Box>
                                                        <span>{numeral(seatVenta.total).format('$0,0.00')}</span>
                                                        <Radio checked={selectedSeats.includes(seat)} color="primary" onClick={e => handleUserSelectedSeats(e, seat, seatVenta['total'])} />
                                                    </Box>
                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <SingleCheck venta={seatVenta} />
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })
                        }
                        <Table>
                            <TableFooter >
                                <TableRow >
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell>
                                        <LabelTotales label="Subtotal:" value={venta.subtotal} />
                                        <LabelTotales label="Descuento:" value={venta.descuento} />
                                        <LabelTotales label="Impuesto:" value={venta.impuesto} />
                                        <b>
                                            <LabelTotales label="Total:" value={venta.total} />
                                        </b>
                                    </StyledTableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TabPanel>
                </>
             ) : (
                  <SingleCheck venta={venta} />
                )}
        </>
    );
}


