// Constants
export const CENTS_PER_UNIT = 100; // For currency conversion
export const CURRENCY_FORMAT = "$0,0.00";

// Utility functions
export const convertToCents = (value) => {
  return parseInt(value.replace(/\D/g, "").padStart(2, "0"), 10);
};

export const formatCurrency = (value) => {
  return (value / CENTS_PER_UNIT).toFixed(2);
};

export const convertToStripeFormat = (value) => {
  return Math.round(value * CENTS_PER_UNIT);
};
